Revolution.Module.extend({
    NAME: "mde.popup", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)

    initialize: function ($) {
        // appelé aussitôt la page chargée
        //console.log("module " + this.NAME);

        this.popUpVideo();
        this.magnificPopup();
    },

    onWindowLoad: function (e) {
        //
    },
    onWindowScroll: function (e) {
        //
    },
    onWindowResize: function (e) {
        //
    },
    popUpVideo: function (e) {
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false,
            closeMarkup: '<button title="Fermer (Esc)" type="button" class="mfp-close">×</button>'
        });
    },

    magnificPopup: function () {
        $('.js-show-popin').magnificPopup({
            type: 'inline',
            preloader: false,
            focus: '#name',
            fixedContentPos: true,
            closeMarkup: '<button title="Fermer (Esc)" type="button" class="mfp-close">×</button>',
            callbacks: {
                beforeOpen: function() {
                    if($(window).width() < 500) {
                        this.st.focus = false;
                    } else {
                        this.st.focus = '#name';
                    }
                }
            }
        });

        $('.js-close-popup').on('click', function() {
            $(this).closest('.popin').find('.mfp-close').click();
        });
    },
});
Revolution.Module.extend({
    NAME: "mde.main", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)

    initialize: function ($) {
        // appelé aussitôt la page chargée
        //console.log("module " + this.NAME);

        //_.bindAll(this, "myfunction", "runEqualHeight", "getHeightsForOffsets");

        //Example to call an external function
        //var UTILS = Revolution.get("mde._utils");
        //UTILS.equalheight($('.bk-mosaique .js-equal-height'));

        //prevent pace.js loading bug
        setTimeout(function () {
            $('body').removeClass('pace-running').addClass('pace-done');
        }, 2000);

        // Lazy Load
        window.bLazy = new Blazy({ selector: '.lazy', loadInvisible: true });

        this.setScrollToTopVisibility();
        $('.btn-back-to-top').click(this.goToTopPage);

        $('body').on('click', '.icon-print', function () {
            window.print()
        });

        $('.icon-print').parent('button').on('keyup', function (e) {
            if (e.which === 13) {
                window.print();
            }
        });

        this.listLevelsSettings();

        shave('.bk-promote .title', 200);
        shave('.bk-promote.-sm .title', 100);
        shave('.bk-card .title', 150);

        $('body').on('click', '.js-link-card', this.linkToCard);

        $('body').on('keyup', '.js-link-card', function (e) {
            if (e.which === 13) {
                window.location = $(this).data('link-card');
            }
        });
    },

    onWindowLoad: function (e) {
        //
    },
    onWindowScroll: function (e) {
        this.setScrollToTopVisibility();
        this.stickyBtnsApparition();

    },
    onWindowResize: function (e) {
        this.setScrollToTopVisibility();
    },

    goToTopPage: function () {
        $('html, body').animate({
            scrollTop: 0
        }, 500);
        Revolution.get("mde.accessible-function").giveTempFocus($('body'));
    },

    setScrollToTopVisibility: function (e) {
        var $btn = $('.btn-back-to-top');
        var offset = $(window).height();

        if ($(window).scrollTop() > offset) {
            $btn.addClass('visible');
        } else {
            $btn.removeClass('visible');
        }
    },

    listLevelsSettings: function (e) {
        $('ul.-secondary').find('.-secondary').removeClass('-secondary');
    },

    linkToCard: function (e) {
        var $obj = $(e.currentTarget)
        var cardLink = $obj.data("link-card");

        window.location = cardLink;
    },

    stickyBtnsApparition: function (e) {
        var scroll = $(window).scrollTop();

        if (scroll > 500) {
            $('.bk-floatings-btns').removeClass('disabled');

        } else {
            $('.bk-floatings-btns').addClass('disabled');
        }
    }
});
